<template>
  <div>
    <b-card
      v-if="isReportInProgress"
      bg-variant="gray-200"
      class="mb-3 mb-lg-5 border-0"
    >
      <p class="mb-0">
        Report acquistato. L'analisi è in creazione, attendere prego.
      </p>
    </b-card>
    <b-card
      v-else
      bg-variant="warning"
      border-variant="warning"
      class="mb-3 mb-lg-5"
    >
      <p class="mb-0">
        Non sono presenti dati sufficienti per completare il profilo.
        Effettua l'acquisto del nostro report per avere un'analisi completa.
      </p>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'ProfileWarning',
  components: {},
  props: {
    isReportInProgress: Boolean,
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
